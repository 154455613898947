@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import 'node_modules/bootstrap/scss/bootstrap';

// themes - our custom or/and out of the box themes
@import './themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

@import 'font-awesome/scss/font-awesome';

@import "./theme-override";

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

;

[dir='ltr'] .nb-theme-corporate ul:not(.tabset) {
  padding: 0;
}

.btn-default {
  background-color: #f0f0f0;
}

.form-group {
  width: 100%;
}

button, .btn {
  cursor: pointer !important;
  margin: 0 5px;
}

.crochet {
  position: absolute;
  height: 0;
  width: 0;
  top: 1px;
  border-style: solid;
  border-width: 5px 5px 0 0;
  border-color: black transparent transparent;
}

.milestone {
  border-right-width: 10px;
  border-left-width: 10px;
  border-top-width: 10px;
  border-right-color: transparent;
  border-left-color: transparent;
  border-style: solid solid none;
  border-bottom: none;
}

.milestone-top {
  @extend .milestone;
  transform: scaleY(-1);
}

.milestone-bottom {
  @extend .milestone;
  top: 10px;
}

html {
  font-size: 12px;
}

.nb-theme-corporate {
  nb-layout .layout {
    height: 100%;

    .layout-container .content {
      overflow: auto;

      .columns {
        flex: 1 0 auto !important;

        nb-layout-column {
          padding-top: 0.25rem;
        }
      }
    }
  }

  nb-sidebar {
    .main-container {
      border: 1px solid #cdd5dc;
      height: calc(100vh - 4.75rem) !important;
      background: #e3e9ee;
      //box-shadow: 1px 5px 5px red;
      .scrollable nb-menu > .menu-items > li.menu-item {
        border-bottom: 1px solid #cdd5dc;
      }

      nb-menu .menu-item {
        border-color: #cdd5dc !important;
      }

      nb-menu .menu-item a {
        font-size: 1rem !important;
      }
    }

    h1 {
      margin-top: 0.25em;
    }

    &.compacted nb-menu .menu-items > li.menu-item i {
      margin-right: 0.5rem;
    }
  }
}

.settings-sidebar.fixed.end.expanded, .settings-sidebar.fixed.end.expanded .main-container {
  width: 23rem;
}

.nb-theme-corporate nb-sidebar .main-container {
  height: calc(100vh - 57px);
}

.btn.btn-primary {
  background-color: #337ab7;
  color: #ffffff;
}

input.form-control {
  height: auto;
}

.gestion-moyen-container ngx-gestion-moyens-section {
  padding-left: 0;
}

ul, ul li {
  list-style: none;
}

.btn.disabled, .btn:disabled {
  filter: grayscale(40%);
  cursor: not-allowed !important;
}

ag-grid-angular.ag-theme-balham {
  font-size: 0.9rem;

  .ag-header {
    font-size: 0.95rem;
  }

  .ag-selection-checkbox span {
    margin-right: 0;
  }

  .ag-cell {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.nb-theme-corporate {

  /*ej-gantt.gantt-control {
    height: calc(100vh - 227px) !important;
  }
  .e-gantt .e-gantt-Spliter {
    height: calc(100% - 40px) !important;
  }*/
  .e-input, .e-tooltipbox, .e-btn, .e-time-popup, .e-datepicker.e-calendar, .e-menu, .e-uploadbox .e-file-name-txt,
  .e-uploadbox .e-selectpart, .e-uploadbox .e-uploadbtn, .e-uploadbox .e-uploadclosebtn, .e-waitpopup-pane .e-text,
  .e-tagcloud, .e-toolbar, .e-progressbar, .e-scale .e-tick .tick-value, .e-datetime-popup .e-dt-button, .e-grid,
  .e-ejinputtext, .e-pager, .e-fileexplorer, .e-textbox, .e-textarea {
    font-size: 1rem;
  }

  .e-ganttchart .e-schedule-day-headercell, .e-ganttchart .e-schedule-hour-headercell,
  .e-ganttchart .e-schedule-week-headercell {
    font-size: 0.95rem;
  }

  .e-treegrid .e-headercelldiv {
    font-size: 1rem;
  }

  .e-datetime-wrap {
    height: 2.2rem;
    margin-top: 0.1rem;
  }

  .e-datetime-wrap.e-disable {
    background-color: #e9ecef;
    border-color: #ccc;
    opacity: 1;
  }

  .e-disable {
    background-color: #e9ecef;
    opacity: 1;
  }

  .e-datewidget {
    display: block;
    overflow: visible;
    height: 29px;
  }

  ngb-pagination ul.pagination {
    padding: 0;
    margin-top: 10px;
  }

  ngx-login nb-layout .layout {
    background: white;
  }

  [data-icon]::before {
    content: none;
  }

  .e-treegridadaptivedialog {
    margin-left: 16px;
  }

  a {
    color: #007bff;

    &:hover, &:focus {
      color: #001296;
    }
  }
}

.active-button {
  width: 75px;
}

#edit .e-icon {
  background-size: contain;
}

.scrollable {
  margin-bottom: 0;
}

.parent-taskbar-content {
  position: relative;

  & .parent-taskbar {
    height: 1px !important;
    background-color: black;
  }

  & .crochet-left {
    @extend .crochet;
    left: -1px;
  }

  & .crochet-right {
    @extend .crochet;
    right: -1px;
    transform: rotate(90deg);
  }
}

ngx-modale-consultation-photo > div {
  max-height: 100vh;

  .modal-dialog {
    max-height: 100%;

    .modal-content {
      max-height: 100%;

      .modal-body {
        flex: 0 1 auto;
        overflow: auto;
      }
    }
  }
}

.content-pushed {
  width: calc(100% - 23rem);
}

.e-datepicker .e-week-header, .e-ntouch.e-datepicker .e-week-header:hover {
  background: #ececec;
}

.e-ntouch.e-datepicker table td:hover, .e-datepicker td.e-state-hover,
.e-ntouch.e-datepicker .current-month.e-state-default.e-special-day:hover, .e-ntouch.e-timewidget .e-select:hover,
.e-time-popup.e-popup .e-hover, .e-ntouch.e-datetime-popup .e-dt-button:hover {
  background: #ececec;
}


.e-datepicker .e-active, .e-datepicker .e-active:hover, .e-datepicker .e-active.e-state-hover, .e-time-popup .e-active {
  background: #378fc4;
}

.e-datepicker table td.other-month, .e-datepicker .e-allyear-first, .e-datepicker .e-allyear-last,
.e-datepicker .e-year-first, .e-datepicker .e-year-last {
  color: #378fc4;
}

.e-datepicker .e-header .e-text .e-datepicker-headertext {
  text-transform: capitalize;
}

.content-pushed, .container-fuild {
  height: calc(100% - 12px);
}

.tox-tinymce {
  min-height: inherit !important;
  height: 100% !important;
  overflow-y: visible !important;
}

.tox-edit-area {
  min-height: 40px !important;
  overflow-y: visible !important;
}

.tox-edit-area__iframe {
  overflow: hidden !important;
}

.tox-sidebar-wrap {
  overflow: visible !important;
}

// * *** SCROLLBAR *** * //
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #757575;
  border: 0 none #ffffff;
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary-bg-subtle-dark;
}

::-webkit-scrollbar-thumb:active {
  background: $primary;
}

::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0 none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #f7f7f7;
}

::-webkit-scrollbar-track:active {
  background: #eaeaea;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

// * *** ///////// *** * //
.nb-theme-corporate .cdk-overlay-container {
  z-index: 1200 !important;
}
