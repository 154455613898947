/*
 * This stylesheets is here to temporarly fix the app style after the nebular migration.
 * This will be removed along with nebular as soon as possible.
 */

/* HEADER */

nb-layout-header nav {
}

nb-user .user-picture {
  border: 2px solid #bcc3cc !important;
}

.nb-icon {
  font-size: 1.25rem;
  line-height: 1;
  width: 1.75em;
  height: 1.75em;
}
